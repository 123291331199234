<template>
  <main>
    <section class="section">
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
          <sqr-crumb name="relation-offers" :bid="bid" :rid="rid" label="breadcrumb_relation_offers"/>
        </ul>
      </nav>
      <!-- <div class="field">
        <sqr-router-link icon="plus" label="relation_offers_add_vehicule" to-name="relation-offer-vehicule-add" :to-bid="bid" :to-rid="rid" color="primary"/>
      </div> -->
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>{{$t('offer_created')}}</th>
            <th>{{$t('offer_reference')}}</th>
            <th>{{$t('offer_type')}}</th>
            <th>{{$t('offer_name')}}</th>
            <th>{{$t('offer_updated_by_name')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in records" :key="record.id">
            <td>{{record.created | datetime}}</td>
            <td>
              <sqr-router-link
                to-name="relation-offer-vehicule"
                :bid="bid"
                :rid="rid"
                :to-id="record.id"
                :label-raw="record.reference"
                is-link
              />
            </td>
            <td>{{record.type}}</td>
            <td>{{record.name}}</td>
            <td>{{record.updatedByName}}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import datetime from '@/filters/datetime';
import relation from './relation';
export default {
  name: 'RelationOffers',
  mixins: [relation],
  filters: {datetime},
  computed: {
    ...mapGetters('offers', ['records', 'loading', 'loadError'])
  },
  mounted () {
    this.sub({
      path: ['brokers', this.bid, 'offers'],
      where: [['relationId', '==', this.rid]]
    })
  },
  methods: {
    ...mapActions('offers', ['sub'])
  }
}
</script>
