import {DateTime} from 'luxon';
export default function (value) {
  if (!value) {
    return 'N/A';
  } else if (typeof value === 'string') {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
  } else {
    return value.toString();
  }
};
